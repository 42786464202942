<template>
    <div class="row">
        <div class="col-md-4">
            <!-- Inicio Tipo sistema -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Añadir Tramitador</h3>
                    <div class="card-tools">
                        <button type="button" @click="añadirTramitador()" class="btn btn-block btn-default btn-xs">Añadir</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label>Usuario&nbsp;</label>
                        <select class="custom-select form-control-sm"  v-model="seleccionado">
                            <option v-for="usuario in usuarios.datos" :value="usuario.idusuario" :key="usuario.idusuario"> {{usuario.nombre}} </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="row">    
        <compania-tramitador-usuario :id="id" v-for="tramitador in tramitadores.datos" :key="tramitador.idusuario" :companiasTramitadores="companiasTramitadores" :tramitador="tramitador"/>
    </div>
</template>
<script>
import CompaniaTramitadoresUsuario from './CompaniaTramitadoresUsuario.vue';
import $ from 'jquery';
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    props: ['id'],
    data() {
        return {
            tramitadores: [],
            seleccionado: '',
            usuarios: [],
            companiasTramitadores: [],
            idtramitador: '',
        }
    },
  components: {
      'compania-tramitador-usuario': CompaniaTramitadoresUsuario,
    },
    methods: {
        async listaUsuarios() { 
            /* get  modulos/pwgsapi/index.php/usuarios */
            const api = new PwgsApi;
            this.usuarios = await api.get('usuarios/gestion');
        },
        async listaTramitadores(id) { 
            /* get  modulos/pwgsapi/index.php/companias/:id/tramitadores */
            const api = new PwgsApi;
            this.tramitadores = await api.get('companias/' + id + '/tramitadores');
        },
        async cargaTramitadores() { 
            /* get  modulos/pwgsapi/index.php/companias/:id */
            const api = new PwgsApi;
            for (var i = 0; i <= this.tramitador.companias_asignadas.length; i++) {
                this.companiasTramitadores[i] = await api.get('companias/' + this.tramitador.companias_asignadas[i]);
            }
        },
        async añadirTramitador() {
            /* post  modulos/pwgsapi/index.php/companias/:id/tramitadores */
            const api = new PwgsApi;
            let subidadatos = { id_usuario: this.seleccionado };
            await api.post('companias/' + this.$props.id + '/tramitadores', subidadatos);
            this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Tramitador añadido correctamente', life: 2000 });
            this.listaTramitadores(this.$props.id);
        },
    },

    mounted() {
        this.listaUsuarios();
        this.listaTramitadores(this.$props.id);
    $('.select2').select2({
      theme: 'bootstrap4'
    })
    },
    watch: {
        id() {
            this.listaUsuarios();
            this.listaTramitadores(this.$props.id);
            $('.select2').select2({
                theme: 'bootstrap4'
            })
        }
    }
}
</script>
